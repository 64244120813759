import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectIdioma', method: 'idioma.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectPais', method: 'pais.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTcliente', method: 'tcliente.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalConocimiento', method: 'canalConocimiento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteComercial', method: 'agenteComercial.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTdireccion', method: 'tdireccion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTvia', method: 'tvia.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectZona', method: 'zona.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTtelefono', method: 'ttelefono.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEmpresa', method: 'empresa.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTtarifa', method: 'ttarifa.select', params: { filter: apiFilterEstadoActivo } })
    // TSECTOR no tiene campo estado pero tiene el campo borrado
    const apiFilter = new APIFilter()
    apiFilter.addExact('borrado', 0)
    apiCalls.push({ name: 'selectTsector', method: 'tsector.select', params: { filter: apiFilter } })
    // parámetros
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectProvincia (Vue, idpais) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpais', idpais)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('provincia.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLocalidad (Vue, idprovincia) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idprovincia', idprovincia)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('localidad.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
