<template>
  <b10-base>
    <v-stepper
      vertical
      non-linear
      :value="currentStep"
      class="elevation-0"
    >
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.clientePotencial.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.clientePotencial.title }}
          <small>{{ steps.clientePotencial.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.clientePotencial.id"
        >
          <v-checkbox
            v-model="form.prospecto"
            label="Prospecto"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="nombre"
            :rules="formRules.nombre"
          >
            <v-text-field
              v-model="form.nombre"
              clearable
              flat
              label="Nombre"
              :error-messages="errors"
              @change="changeNombre"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="razon_social"
            :rules="formRules.razon_social"
          >
            <v-text-field
              v-model="form.razon_social"
              clearable
              flat
              label="Razón social"
              :error-messages="errors"
              :append-icon="form.razon_social ? null : $vuetify.icons.values.equals"
              @click:append="clickCopyNombreARazonSocial"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="cif"
            :rules="formRules.cif"
          >
            <v-text-field
              v-model="form.cif"
              clearable
              flat
              label="NIF"
              :error-messages="errors"
              :messages="messagesCIF"
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.idtcliente"
            :items="tclientes"
            item-value="idtcliente"
            item-text="descripcion"
            label="Tipo de cliente"
            clearable
          />
          <b10-autocomplete
            v-model="form.idtsector"
            :items="tsectores"
            item-value="idtsector"
            item-text="descripcion"
            label="Actividad"
            clearable
          />
          <b10-autocomplete
            v-model="form.idcanal_conocimiento"
            :items="canalesConocimiento"
            item-value="idcanal_conocimiento"
            item-text="descripcion"
            label="Canal de conocimiento"
            clearable
          />
          <b10-autocomplete
            v-model="form.idagente_comercial"
            :items="agentesComerciales"
            item-value="idagente_comercial"
            item-text="nombre"
            label="Agente"
            clearable
          />
          <b10-textarea
            v-model="form.observaciones"
            label="Observaciones"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.direccion.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.direccion.title }}
          <small>{{ steps.direccion.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.direccion.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idtdireccion"
            :rules="formRules.direccion_idtdireccion"
          >
            <b10-autocomplete
              v-model="form.direccion_idtdireccion"
              :items="tdirecciones"
              item-value="idtdireccion"
              item-text="descripcion"
              label="Tipo de dirección"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idzona"
            :rules="formRules.direccion_idzona"
          >
            <b10-autocomplete
              v-model="form.direccion_idzona"
              :items="zonas"
              item-value="idzona"
              item-text="descripcion"
              label="Zona"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idtvia"
            :rules="formRules.direccion_idtvia"
          >
            <b10-autocomplete
              v-model="form.direccion_idtvia"
              :items="tvias"
              item-value="idtvia"
              item-text="descripcion"
              label="Tipo de vía"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_calle"
            :rules="formRules.direccion_calle"
          >
            <v-text-field
              v-model="form.direccion_calle"
              clearable
              flat
              label="Calle"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-text-field
            v-model="form.direccion_num"
            clearable
            flat
            label="Número"
          />
          <v-text-field
            v-model="form.direccion_otros_datos"
            clearable
            flat
            label="Otros datos"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idpais"
            :rules="formRules.direccion_idpais"
          >
            <b10-autocomplete
              v-model="form.direccion_idpais"
              :items="paises"
              item-value="idpais"
              item-text="descripcion"
              label="País"
              clearable
              :error-messages="errors"
              @change="direccionIdpaisSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idprovincia"
            :rules="formRules.direccion_idprovincia"
          >
            <b10-autocomplete
              v-model="form.direccion_idprovincia"
              :items="provincias"
              item-value="idprovincia"
              item-text="descripcion"
              label="Provincia"
              clearable
              :error-messages="errors"
              @change="direccionIdprovinciaSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_idlocalidad"
            :rules="formRules.direccion_idlocalidad"
          >
            <b10-autocomplete
              v-model="form.direccion_idlocalidad"
              :items="localidades"
              item-value="idlocalidad"
              item-text="descripcion"
              label="Localidad"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_cp"
            :rules="formRules.direccion_cp"
          >
            <v-text-field
              v-model="form.direccion_cp"
              clearable
              flat
              label="Código postal"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.direccionServicio.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.direccionServicio.title }}
          <small>{{ steps.direccionServicio.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.direccionServicio.id"
        >
          <ValidationProvider
            name="direccion_servicio"
          >
            <v-checkbox
              v-model="form.direccion_servicio"
              label="La dirección del servicio es diferente a la fiscal/postal"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idtdireccion"
            :rules="formRules.direccion_servicio_idtdireccion"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idtdireccion"
              :items="tdirecciones"
              item-value="idtdireccion"
              item-text="descripcion"
              label="Tipo de dirección"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idzona"
            :rules="formRules.direccion_servicio_idzona"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idzona"
              :items="zonas"
              item-value="idzona"
              item-text="descripcion"
              label="Zona"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idtvia"
            :rules="formRules.direccion_servicio_idtvia"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idtvia"
              :items="tvias"
              item-value="idtvia"
              item-text="descripcion"
              label="Tipo de vía"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_calle"
            :rules="formRules.direccion_servicio_calle"
          >
            <v-text-field
              v-model="form.direccion_servicio_calle"
              clearable
              flat
              label="Calle"
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
          <v-text-field
            v-model="form.direccion_servicio_num"
            clearable
            flat
            label="Número"
            :disabled="!form.direccion_servicio"
          />
          <v-text-field
            v-model="form.direccion_servicio_otros_datos"
            clearable
            flat
            label="Otros datos"
            :disabled="!form.direccion_servicio"
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idpais"
            :rules="formRules.direccion_servicio_idpais"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idpais"
              :items="paises"
              item-value="idpais"
              item-text="descripcion"
              label="País"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
              @change="direccionServicioIdpaisSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idprovincia"
            :rules="formRules.direccion_servicio_idprovincia"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idprovincia"
              :items="provinciasServicio"
              item-value="idprovincia"
              item-text="descripcion"
              label="Provincia"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
              @change="direccionServicioIdprovinciaSelected"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_idlocalidad"
            :rules="formRules.direccion_servicio_idlocalidad"
          >
            <b10-autocomplete
              v-model="form.direccion_servicio_idlocalidad"
              :items="localidadesServicio"
              item-value="idlocalidad"
              item-text="descripcion"
              label="Localidad"
              clearable
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="direccion_servicio_cp"
            :rules="formRules.direccion_servicio_cp"
          >
            <v-text-field
              v-model="form.direccion_servicio_cp"
              clearable
              flat
              label="Código postal"
              :error-messages="errors"
              :disabled="!form.direccion_servicio"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.personaContacto.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.personaContacto.title }}
          <small>{{ steps.personaContacto.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.personaContacto.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="telefono_idttelefono"
            :rules="formRules.telefono_idttelefono"
          >
            <b10-autocomplete
              v-model="form.telefono_idttelefono"
              :items="ttelefonos"
              item-value="idttelefono"
              item-text="descripcion"
              label="Tipo de persona de contacto"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="telefono_telefono"
            :rules="formRules.telefono_telefono"
          >
            <v-text-field
              v-model="form.telefono_telefono"
              clearable
              flat
              label="Teléfono"
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="telefono_email"
            :rules="formRules.telefono_email"
          >
            <v-text-field
              v-model="form.telefono_email"
              clearable
              flat
              label="Correo electrónico"
              :error-messages="errors"
            />
          </ValidationProvider>
          <v-text-field
            v-model="form.telefono_nombre"
            clearable
            flat
            label="Nombre y apellidos"
            :append-icon="form.telefono_nombre ? null : $vuetify.icons.values.equals"
            @click:append="clickCopyNombreATelefonoNombre"
          />
          <v-text-field
            v-model="form.telefono_horario"
            clearable
            flat
            label="Horario"
          />
          <b10-textarea
            v-model="form.telefono_observaciones"
            label="Observaciones"
          />
          <v-checkbox
            v-model="form.telefono_para_todos_los_sistemas"
            label="Disponible en todos los sistemas"
          />
          <v-subheader>Para notificaciones de</v-subheader>
          <v-checkbox
            v-model="form.telefono_email_notifica_sat"
            label="Servicio técnico"
          />
          <v-checkbox
            v-model="form.telefono_email_notifica_comercial"
            label="Comerciales"
          />
          <v-checkbox
            v-model="form.telefono_email_notifica_admon"
            label="Administración"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          :step="steps.representante.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.representante.title }}
          <small>{{ steps.representante.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.representante.id"
        >
          <ValidationProvider
            name="representante"
          >
            <v-checkbox
              v-model="form.representante"
              label="Introducir representante"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="representante_nombre"
            :rules="formRules.representante_nombre"
          >
            <v-text-field
              v-model="form.representante_nombre"
              clearable
              flat
              label="Nombre y apellidos"
              :error-messages="errors"
              :disabled="!form.representante"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="representante_dni"
            :rules="formRules.representante_dni"
          >
            <v-text-field
              v-model="form.representante_dni"
              clearable
              flat
              label="DNI"
              :error-messages="errors"
              :messages="messagesDNIRepresentante"
              :disabled="!form.representante"
            />
          </ValidationProvider>
          <v-text-field
            v-model="form.representante_domicilio"
            clearable
            flat
            label="Domicilio"
            :disabled="!form.representante"
          />
          <v-text-field
            v-model="form.representante_cargo"
            clearable
            flat
            label="Cargo"
            :disabled="!form.representante"
          />
        </v-stepper-content>
      </ValidationObserver>
      <ValidationObserver
        v-slot="slotObserverProps"
      >
        <v-stepper-step
          v-if="empresas.length > 1 || ttarifas.length > 1"
          :step="steps.masDatos.id"
          editable
          :rules="[value => !slotObserverProps.validated || slotObserverProps.valid]"
        >
          {{ steps.masDatos.title }}
          <small>{{ steps.masDatos.subtitle }}</small>
        </v-stepper-step>
        <v-stepper-content
          :step="steps.masDatos.id"
        >
          <ValidationProvider
            v-slot="{ errors }"
            name="ididioma"
            :rules="formRules.ididioma"
          >
            <b10-autocomplete
              v-model="form.ididioma"
              :items="idiomas"
              item-value="ididioma"
              item-text="descripcion"
              label="Idioma"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <b10-autocomplete
            v-model="form.idpais"
            :items="paises"
            item-value="idpais"
            item-text="descripcion"
            label="Nacionalidad"
            clearable
          />
          <ValidationProvider
            v-if="empresas.length > 1"
            v-slot="{ errors }"
            name="idempresa"
            :rules="formRules.idempresa"
          >
            <b10-autocomplete
              v-model="form.idempresa"
              :items="empresas"
              item-value="idempresa"
              item-text="nombre"
              label="Empresa"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="idttarifa"
            :rules="formRules.idttarifa"
          >
            <b10-autocomplete
              v-if="ttarifas.length > 1"
              v-model="form.idttarifa"
              :items="ttarifas"
              item-value="idttarifa"
              item-text="descripcion"
              label="Tipo de tarifa"
              clearable
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-stepper-content>
      </ValidationObserver>
    </v-stepper>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClientePotencialAddFormData'
import { PARAMETRO } from '@/utils/consts'
import { isValidNIF } from '@/utils/validate'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        // cliente potencial
        prospecto: false,
        nombre: null,
        razon_social: null,
        cif: null,
        idtcliente: null,
        idtsector: null,
        idcanal_conocimiento: null,
        idagente_comercial: null,
        observaciones: null,
        // dirección
        direccion_idtdireccion: null,
        direccion_idzona: null,
        direccion_idtvia: null,
        direccion_calle: null,
        direccion_num: null,
        direccion_otros_datos: null,
        direccion_cp: null,
        direccion_idlocalidad: null,
        direccion_idprovincia: null,
        direccion_idpais: null,
        // dirección de servicio
        direccion_servicio: false,
        direccion_servicio_idtdireccion: null,
        direccion_servicio_idzona: null,
        direccion_servicio_idtvia: null,
        direccion_servicio_calle: null,
        direccion_servicio_num: null,
        direccion_servicio_otros_datos: null,
        direccion_servicio_cp: null,
        direccion_servicio_idlocalidad: null,
        direccion_servicio_idprovincia: null,
        direccion_servicio_idpais: null,
        // persona de contacto
        telefono_para_todos_los_sistemas: true,
        telefono_idttelefono: null,
        telefono_telefono: null,
        telefono_email: null,
        telefono_nombre: null,
        telefono_observaciones: null,
        telefono_horario: null,
        telefono_email_notifica_admon: true,
        telefono_email_notifica_comercial: true,
        telefono_email_notifica_sat: true,
        // representante
        representante: false,
        representante_nombre: null,
        representante_dni: null,
        representante_domicilio: null,
        representante_cargo: null,
        // más datos
        ididioma: null,
        idpais: null,
        idempresa: null,
        idttarifa: null,
      },
      formRules: {
        nombre: { required: true },
        razon_social: { required: true },
        cif: { required: true },
        direccion_idtdireccion: { required: true },
        direccion_idzona: { required: true },
        direccion_idtvia: { required: true },
        direccion_calle: { required: true },
        direccion_idpais: { required: true },
        direccion_idprovincia: { required: true },
        direccion_idlocalidad: { required: true },
        direccion_cp: { required: true },
        direccion_servicio_idtdireccion: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_idzona: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_idtvia: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_calle: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_idpais: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_idprovincia: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_idlocalidad: { required_if: { target: 'direccion_servicio', value: true } },
        direccion_servicio_cp: { required_if: { target: 'direccion_servicio', value: true } },
        telefono_idttelefono: { required: true },
        telefono_telefono: { required: true },
        telefono_email: { required: true, email: true },
        representante_nombre: { required_if: { target: 'representante', value: true } },
        representante_dni: { required_if: { target: 'representante', value: true } },
        ididioma: { required: true },
        idempresa: { required: true },
        idttarifa: { required: true },
      },
      steps: {
        clientePotencial: {
          id: 1,
          title: 'Cliente potencial',
          subtitle: 'Datos principales del cliente potencial',
        },
        direccion: {
          id: 2,
          title: 'Dirección',
          subtitle: 'Dirección fiscal y postal',
        },
        direccionServicio: {
          id: 3,
          title: 'Dirección de servicio',
          subtitle: 'Si es diferente a la fiscal/postal',
        },
        personaContacto: {
          id: 4,
          title: 'Datos de contacto',
          subtitle: 'Datos de la primera persona de contacto',
        },
        representante: {
          id: 5,
          title: 'Representante',
          subtitle: 'Solo para empresas',
        },
        masDatos: {
          id: 6,
          title: 'Más datos...',
          subtitle: 'Otros datos para crear el cliente potencial',
        },
      },
      currentStep: 1,
      idiomas: [],
      paises: [],
      tclientes: [],
      tsectores: [],
      canalesConocimiento: [],
      agentesComerciales: [],
      tdirecciones: [],
      zonas: [],
      tvias: [],
      ttelefonos: [],
      empresas: [],
      ttarifas: [],
      localidades: [],
      localidadesServicio: [],
      provincias: [],
      provinciasServicio: [],
    }
  },
  computed: {
    messagesCIF () {
      if (!this.form.cif || isValidNIF(this.form.cif)) {
        return []
      } else {
        return ['El formato del NIF no es válido']
      }
    },
    messagesDNIRepresentante () {
      if (!this.form.representante_dni || isValidNIF(this.form.representante_dni)) {
        return []
      } else {
        return ['El formato del NIF no es válido']
      }
    },
  },
  async created () {
    const parametrosNecesarios = [
      PARAMETRO.parametros.EMPRESA_DEFECTO,
      PARAMETRO.parametros.PAIS_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_CANAL_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_CLIENTE_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_DIRECCION_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_SECTOR_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_TARIFA_POR_DEFECTO,
      PARAMETRO.parametros.TIPO_TELEFONO_DEFECTO,
      PARAMETRO.parametros.TIPO_VIA_POR_DEFECTO,
      PARAMETRO.parametros.ZONA_POR_DEFECTO,
      PARAMETRO.parametros.IDIOMA_DEFECTO,
    ]
    const resp = await Data.selectLookups(this, parametrosNecesarios)
    this.idiomas = resp.data.selectIdioma.result.dataset
    this.paises = resp.data.selectPais.result.dataset
    this.tclientes = resp.data.selectTcliente.result.dataset
    this.tsectores = resp.data.selectTsector.result.dataset
    this.canalesConocimiento = resp.data.selectCanalConocimiento.result.dataset
    this.agentesComerciales = resp.data.selectAgenteComercial.result.dataset
    this.tdirecciones = resp.data.selectTdireccion.result.dataset
    this.zonas = resp.data.selectZona.result.dataset
    this.tvias = resp.data.selectTvia.result.dataset
    this.ttelefonos = resp.data.selectTtelefono.result.dataset
    this.empresas = resp.data.selectEmpresa.result.dataset
    this.ttarifas = resp.data.selectTtarifa.result.dataset
    // valores por defecto
    const idiomaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.IDIOMA_DEFECTO })
    if (idiomaDefecto) {
      const existeIdiomaDefecto = _.find(this.idiomas, {ididioma: parseInt(idiomaDefecto.valor)})
      if (existeIdiomaDefecto) {
        this.$set(this.form, 'ididioma', parseInt(idiomaDefecto.valor))
      }
    }
    const paisDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.PAIS_POR_DEFECTO })
    let existePaisDefecto = null
    if (paisDefecto) {
      existePaisDefecto = _.find(this.paises, {idpais: parseInt(paisDefecto.valor)})
      if (existePaisDefecto) {
        this.$set(this.form, 'idpais', parseInt(paisDefecto.valor))
      }
    }
    const tclienteDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_CLIENTE_POR_DEFECTO })
    if (tclienteDefecto) {
      const existeTclienteDefecto = _.find(this.tclientes, {idtcliente: parseInt(tclienteDefecto.valor)})
      if (existeTclienteDefecto) {
        this.$set(this.form, 'idtcliente', parseInt(tclienteDefecto.valor))
      }
    }
    const tsectorDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_SECTOR_POR_DEFECTO })
    if (tsectorDefecto) {
      const existeTsectorDefecto = _.find(this.tsectores, {idtsector: parseInt(tsectorDefecto.valor)})
      if (existeTsectorDefecto) {
        this.$set(this.form, 'idtsector', parseInt(tsectorDefecto.valor))
      }
    }
    const canalConocimientoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_CANAL_POR_DEFECTO })
    if (canalConocimientoDefecto) {
      const existeCanalConocimientoDefecto = _.find(this.canalesConocimiento, {idcanal_conocimiento: parseInt(canalConocimientoDefecto.valor)})
      if (existeCanalConocimientoDefecto) {
        this.$set(this.form, 'idcanal_conocimiento', parseInt(canalConocimientoDefecto.valor))
      }
    }
    const tdireccionDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_DIRECCION_POR_DEFECTO })
    if (tdireccionDefecto) {
      const existetDireccionDefecto = _.find(this.tdirecciones, {idtdireccion: parseInt(tdireccionDefecto.valor)})
      if (existetDireccionDefecto) {
        this.$set(this.form, 'direccion_idtdireccion', parseInt(tdireccionDefecto.valor))
        this.$set(this.form, 'direccion_servicio_idtdireccion', parseInt(tdireccionDefecto.valor))
      }
    }
    const zonaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.ZONA_POR_DEFECTO })
    if (zonaDefecto) {
      const existeZonaDefecto = _.find(this.zonas, {idzona: parseInt(zonaDefecto.valor)})
      if (existeZonaDefecto) {
        this.$set(this.form, 'direccion_idzona', parseInt(zonaDefecto.valor))
        this.$set(this.form, 'direccion_servicio_idzona', parseInt(zonaDefecto.valor))
      }
    }
    const tviaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_VIA_POR_DEFECTO })
    if (tviaDefecto) {
      const existeTviaDefecto = _.find(this.tvias, {idtvia: parseInt(tviaDefecto.valor)})
      if (existeTviaDefecto) {
        this.$set(this.form, 'direccion_idtvia', parseInt(tviaDefecto.valor))
        this.$set(this.form, 'direccion_servicio_idtvia', parseInt(tviaDefecto.valor))
      }
    }
    if (existePaisDefecto && paisDefecto) {
      this.$set(this.form, 'direccion_idpais', parseInt(paisDefecto.valor))
      this.$set(this.form, 'direccion_servicio_idpais', parseInt(paisDefecto.valor))
      this.direccionIdpaisSelected(this.form.direccion_idpais)
      this.direccionServicioIdpaisSelected(this.form.direccion_servicio_idpais)
    }
    const ttelefonoDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_TELEFONO_DEFECTO })
    if (ttelefonoDefecto) {
      const existeTtelefonoDefecto = _.find(this.ttelefonos, {idttelefono: parseInt(ttelefonoDefecto.valor)})
      if (existeTtelefonoDefecto) {
        this.$set(this.form, 'telefono_idttelefono', parseInt(ttelefonoDefecto.valor))
      }
    }
    const empresaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.EMPRESA_DEFECTO })
    if (empresaDefecto) {
      const existeEmpresaDefecto = _.find(this.empresas, {idempresa: parseInt(empresaDefecto.valor)})
      if (existeEmpresaDefecto) {
        this.$set(this.form, 'idempresa', parseInt(empresaDefecto.valor))
      }
    }
    const ttarifaDefecto = _.find(resp.data.selectParametro.result.dataset, { idparametro: PARAMETRO.parametros.TIPO_TARIFA_POR_DEFECTO })
    if (ttarifaDefecto) {
      const existeTtarifaDefecto = _.find(this.ttarifas, {idttarifa: parseInt(ttarifaDefecto.valor)})
      if (existeTtarifaDefecto) {
        this.$set(this.form, 'idttarifa', parseInt(ttarifaDefecto.valor))
      }
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async direccionIdprovinciaSelected (value) {
      if (value) {
        const [datasetLocalidades] = await Data.selectLocalidad(this, value)
        this.localidades = datasetLocalidades
      } else {
        this.$set(this.form, 'direccion_idlocalidad', null)
        this.localidades = []
      }
    },
    async direccionServicioIdprovinciaSelected (value) {
      if (value) {
        const [datasetLocalidades] = await Data.selectLocalidad(this, value)
        this.localidadesServicio = datasetLocalidades
      } else {
        this.$set(this.form, 'direccion_servicio_idlocalidad', null)
        this.localidadesServicio = []
      }
    },
    async direccionIdpaisSelected (value) {
      if (value) {
        const [datasetProvincias] = await Data.selectProvincia(this, value)
        this.provincias = datasetProvincias
      } else {
        this.$set(this.form, 'direccion_idprovincia', null)
        this.$set(this.form, 'direccion_idlocalidad', null)
        this.provincias = []
        this.localidades = []
      }
    },
    async direccionServicioIdpaisSelected (value) {
      if (value) {
        const [datasetProvincias] = await Data.selectProvincia(this, value)
        this.provinciasServicio = datasetProvincias
      } else {
        this.$set(this.form, 'direccion_servicio_idprovincia', null)
        this.$set(this.form, 'direccion_servicio_idlocalidad', null)
        this.provinciasServicio = []
        this.localidadesServicio = []
      }
    },
    changeNombre () {
      if (!this.form.razon_social) {
        this.clickCopyNombreARazonSocial()
      }
    },
    clickCopyNombreARazonSocial () {
      this.$set(this.form, 'razon_social', this.form.nombre)
    },
    clickCopyNombreATelefonoNombre () {
      this.$set(this.form, 'telefono_nombre', this.form.nombre)
    },
  }
}
</script>

<style>
.v-stepper__label {
  text-shadow: none !important;
}
</style>
